*, html, body, div, p {
  border: 0;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.home {
  background-color: #FFF6E4 !important;
}

.about {
  background-color: #ECECE2 !important;
}

.cart {
  background: #FFFFF4 !important;
}

.cart .main-header {
  background: #FFFFF4 !important;
}

.hero__baner .hero__h1-withe, .hero__baner .hero__text h1, .content h1, .content h2, .products h1, .my__baner2 h2 {
  text-align: center;
  color: #012E1A;
  font-size: 48px;
  font-family: "Oranienbaum", sans-serif;
  font-weight: 400;
}

.menu .menu__no ul a, .hero__baner .hero__text p, .content p, .content .opinions .opinions__box .opinions__text p, .category .category__info p, .category .category__info a p, .contact p,
.cash p,
.return p, .return ul, .blog p {
  font-size: 16px;
  line-height: 29px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-align: center;
}

.content .gift h2, .content .content__choice .content__active a .content__name h3,
.content .content__choice .content__nature a .content__name h3, .category .category__info h2, .category .category__green h2, .blog .h1 {
  font-family: "Oranienbaum", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
}

.product .product__text .product__btn-more, .contact .contact__send,
.cash .contact__send,
.return .contact__send, .blog .blog__btn-next {
  background: rgba(1, 46, 26, 0.2);
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 10px;
  gap: 10px;
}

.hero__baner .hero__text .hero__recomend p, .content .content__set h2, .content .content__choice .content__active a .content__name p,
.content .content__choice .content__nature a .content__name p, .cart h1, .cart .cart__product .cart__quantity button, .cart .cart__product .cart__counter, .contact h1,
.cash h1,
.return h1, .contact .contact__call p,
.cash .contact__call p,
.return .contact__call p, .contact .cash__sum h3,
.cash .cash__sum h3,
.return .cash__sum h3, .blog .article__title, .blog .blog__pagination .pager {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
}

.content .content__gift2 h2, .content .content__gift h2 {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 36px;
  color: #fff;
}

.product .product_element, .product .product__text .modal-content .modal-header .modal-title, .product .product__buy, .blog h5, .blog .article__p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.content .gift h4, .content .film .film__link p, .blog h4 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.menu {
  position: relative;
}

.menu .menu__no ul {
  list-style-type: none;
  position: absolute;
  right: 0;
  top: 10px;
  text-align: end;
}

.menu .menu__no ul a {
  color: #000000;
  text-decoration: none;
  transition: 0.3s;
}

.menu .menu__no ul a:hover {
  color: #10653E;
}

.menu .menu__nav {
  margin-top: 30px;
}

.menu .menu__nav li .shop-menu {
  font-weight: 700;
  color: #10653e;
  transition: 0.3s;
}

.menu .menu__nav li .shop-menu:hover {
  font-weight: 900;
}

.menu .menu__nav li .cart-menu {
  padding-right: 50px !important;
  font-weight: 700;
  color: #10653e;
  transition: 0.3s;
}

.menu .menu__nav li .cart-menu img {
  height: auto;
  transition: 0.3s;
}

.menu .menu__nav li .cart-menu .cart-counter {
  background-color: #fff;
  position: absolute;
  color: #012e1a;
  border-radius: 50%;
  padding: 2px 8px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #012e1a;
  right: 0px;
  top: -18px;
}

.menu .menu__nav li .cart-menu:hover {
  font-weight: 700;
}

.menu .menu__nav li .cart-menu:hover img {
  transform: scale(1.1);
}

.menu .menu__nav li a {
  color: #000000;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19.2px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  padding: 0 0 20px 0;
  transition: 0.3s;
  position: relative;
  transition: 0.3s;
}

.menu .menu__nav li a img {
  position: absolute;
  top: -13px;
}

.menu .menu__nav li a:hover {
  font-weight: 500;
}

.menu .menu__nav li a.active {
  font-weight: 700;
}

.menu .menu__nav li .dropdown-menu {
  text-align: center;
  transform: translate3d(-15px, 39px, 0px) !important;
  background-color: #ECECE2;
  border: 2px solid #012e1a;
  transition: 0.7s;
}

.menu .menu__nav li .dropdown-menu li a {
  padding: 8px 0 8px 0;
  transition: 0.5s;
}

.menu .menu__nav li .dropdown-menu li a:hover {
  background-color: #012e1a;
  color: #fff;
  transition: 0.5s;
}

.menu .menu__logo img {
  padding-bottom: 3px;
}

.menu .menu__left {
  padding-right: 55px;
}

.menu .menu__right {
  padding-left: 55px;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.home .collapse1 {
  position: absolute;
  width: 100% !important;
  max-width: 100% !important;
}

.collapse1 {
  display: none;
  position: absolute;
  z-index: 99;
  width: 100%;
  max-width: 100% !important;
}

.active-menu {
  display: block;
}

.hamburger {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.hamburger:focus > .hamburger__container {
  box-shadow: 0 0 2px 2px #51a7e8;
}

.hamburger:focus, .hamburger__container:focus {
  outline: none;
}

.hamburger__container {
  display: flex;
  align-items: center;
  position: relative;
  width: 35px;
  height: 30px;
}

.hamburger__bars {
  position: absolute;
  width: 35px;
  height: 2px;
  background-color: #000;
  transition: transform 220ms ease-in-out;
}

.hamburger__bars::before, .hamburger__bars::after {
  display: block;
  position: absolute;
  width: 35px;
  height: 2px;
  background-color: #000;
  content: "";
}

.hamburger__bars::before {
  top: -12px;
  transition: top 100ms 250ms ease-in, transform 220ms ease-in-out;
}

.hamburger__bars::after {
  bottom: -12px;
  transition: bottom 100ms 250ms ease-in, transform 220ms ease-in-out;
}

.hamburger--active .hamburger__bars {
  transform: rotate(225deg);
  transition: transform 220ms 120ms ease-in-out;
}

.hamburger--active .hamburger__bars::before {
  top: 0;
  transition: top 100ms ease-out;
}

.hamburger--active .hamburger__bars::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 100ms ease-out, transform 220ms 120ms ease-in-out;
}

header {
  background-color: white;
}

.collapse1 {
  background-color: white;
}

.main-header {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1000;
  transition: .5s;
}

.main-header.not-hide {
  top: 0px !important;
}

.small {
  font-size: var(--bs-body-font-size);
}

.hero__baner {
  position: relative;
  margin: 0 auto;
}

.hero__baner .hero__h1-withe {
  position: absolute;
  color: #fff;
  top: 30px;
}

.hero__baner .hero__text {
  position: absolute;
  transform: translate(95%, 15%);
  align-items: center;
}

.hero__baner .hero__text h1 {
  max-width: 455px;
  margin: auto;
}

.hero__baner .hero__text .hero__recomend {
  width: 50%;
  text-align: center;
  margin: 0 auto;
}

.hero__baner .hero__text .hero__recomend p {
  margin: auto;
  margin-top: 20px;
}

.hero__baner .sliderNature {
  display: none;
}

.content .content__gift2 {
  background-color: #012E1A;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  width: 70%;
  position: relative;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  transition: 0.7s;
}

.content .content__gift2 h2 {
  color: #fff;
  width: 70%;
  transition: 0.7s;
  text-align: left;
  margin: 0;
}

.content .content__gift2 p {
  color: #fff;
  width: 70%;
  font-size: 15px;
  text-align: left;
  margin: 0;
}

.content .content__gift2 img {
  position: absolute;
  height: 200px;
  top: -50px;
  left: 40px;
  transition: 0.7s;
  transform: scale(0.9);
}

.content .gift {
  background-color: #012E1A;
  max-width: 745px;
  color: #fff;
  margin: 0 auto;
}

.content .gift h2 {
  color: #fff;
  font-family: 'Lato', sans-serif;
}

.content .gift h4 {
  font-weight: 400;
  text-align: center;
}

.content .gift .gift__img {
  overflow: hidden;
}

.content .gift .gift__img img {
  transition: .7s;
  object-fit: cover;
}

.content .gift .gift__img:hover img {
  transform: scale(1.1);
}

.content .content__gift {
  background-color: #012E1A;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  transition: 0.7s;
}

.content .content__gift .content__gift-text {
  max-width: 450px;
}

.content .content__gift h2 {
  color: #fff;
  transition: 0.7s;
  text-align: left;
  margin: 0;
  font-size: 20px;
  line-height: 24px;
  padding-left: 35px;
}

.content .content__gift p {
  color: #fff;
  font-size: 15px;
  text-align: left;
  margin: 0;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 35px;
}

.content .content__gift img {
  position: absolute;
  top: -30%;
  left: 5%;
  transition: 0.7s;
}

.content .content__gift:hover {
  transform: scale(1.03);
}

.content .content__gift:hover img {
  transform: scale(1.1);
  transform: rotate(25deg);
}

.content .content__set h2 {
  color: #012E1A;
  text-align: center;
  text-transform: uppercase;
}

.content .content__choice {
  position: relative;
}

.content .content__choice .content__active a,
.content .content__choice .content__nature a {
  position: relative;
  width: 100%;
}

.content .content__choice .content__active a img,
.content .content__choice .content__nature a img {
  width: 100%;
  z-index: -1;
  object-fit: cover;
}

.content .content__choice .content__active a .content__name,
.content .content__choice .content__nature a .content__name {
  opacity: 0;
  background-color: rgba(1, 46, 26, 0.9);
  transition: 0.7s;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  height: 120px;
  width: 90%;
  margin-left: 5%;
  bottom: 5%;
}

.content .content__choice .content__active a .content__name h3,
.content .content__choice .content__nature a .content__name h3 {
  text-align: center;
  color: #fff;
  margin: 0;
}

.content .content__choice .content__active a .content__name p,
.content .content__choice .content__nature a .content__name p {
  text-align: center;
  color: #fff;
  margin: 0;
}

.content .content__choice .content__active :hover .content__name,
.content .content__choice .content__nature :hover .content__name {
  opacity: 1;
}

.content .content__btn {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
}

.content .content__btn:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.content .content__green-text p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #012e1a;
}

.content .film {
  padding: 10px;
  max-width: 745px;
  margin: 40px auto;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 10px;
}

.content .film .film__link {
  padding: 10px;
  text-decoration: none;
}

.content .film .film__link p {
  font-weight: 300;
}

.content .film .film__link .film__yt {
  margin-top: 20px;
}

.content .film iframe {
  border-radius: 12px;
}

.content .opinions {
  padding: 10px;
  max-width: 800px;
  margin: 40px auto;
}

.content .opinions .opinions__box {
  max-width: 400px;
  position: relative;
  margin: 0 auto;
  padding: 10px auto;
  margin-top: 20px;
}

.content .opinions .opinions__box .quote {
  position: absolute;
  top: -15px;
  left: 30px;
  z-index: 10;
}

.content .opinions .opinions__box .opinions__text {
  background-color: #fff;
  border-radius: 12px;
  padding: 55px 35px;
  min-height: 240px;
  position: relative;
}

.content .opinions .opinions__box .opinions__text p {
  text-align: left;
  font-style: italic;
  line-height: 19px;
}

.content .opinions .opinions__box .opinions__text .opinions__mum {
  position: absolute;
  bottom: 40px;
}

.category div {
  padding: 0;
}

.category .category__head h2 {
  text-align: center;
  color: #012e1a;
  font-size: 48px;
  font-family: Oranienbaum, sans-serif;
  font-weight: 400;
}

.category .category__info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.category .category__info h2 {
  text-align: left;
  width: 100%;
  color: #012e1a;
}

.category .category__info p {
  text-align: left;
  color: #012e1a;
}

.category .category__info a {
  text-align: left;
  color: #000;
}

.category .category__info a img {
  transition: 0.7s;
}

.category .category__info a:hover img {
  transform: scale(1.1);
}

.category .category__info .content__more {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  background-color: #10653e;
  margin-top: 30px;
  transition: 0.7s;
}

.category .category__info .content__more:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.category .category__img {
  overflow: hidden;
}

.category .category__img img {
  width: 100%;
  padding: 0;
  object-fit: cover;
  transition: 0.7s;
}

.category .category__img img:hover {
  transform: scale(1.1);
}

.category .category__green {
  background-color: #012e1a;
}

.category .category__green h2 {
  color: #fff;
}

.category .category__green p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180.5%;
  color: #fff;
}

.category .category__boxes .category__item {
  position: relative;
  padding: 5px 10px;
}

.category .category__boxes .category__item img {
  width: 100%;
}

.category .category__boxes .category__item .category__circle {
  top: 10px;
  right: 30px;
  position: absolute;
  width: 106px;
  height: 106px;
  border-radius: 50%;
  background-color: #10653e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  color: #ffffff;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
}

.category .category__boxes .category__item h4 {
  font-family: "Lato";
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.category .category__boxes .category__item p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}

.products h1 {
  font-size: 40px;
}

.products .products__text p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
  text-align: center;
  color: #012e1a;
}

.products .products__box {
  position: relative;
}

.products .products__box .products__img a {
  display: block;
  overflow: hidden;
}

.products .products__box .products__img a img {
  object-fit: cover;
  transition: 0.7s;
  height: 100%;
  width: 100%;
}

.products .products__box .products__img a :hover {
  transform: scale(1.1);
}

.products .products__box .products__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.products .products__box .products__info h5 {
  min-height: 55px;
}

.products .products__box .products__info h5 a {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000;
  text-decoration: none;
}

.products .products__box .products__info .products__desc {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  min-height: 110px;
}

.products .products__box .products__info .products__price {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}

.products .products__box .products__info .products__btn .products__more {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  background-color: #10653e;
  text-align: center;
}

.products .products__box .products__info .products__btn .products__more:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.products .products__box .products__info .products__btn .products__add {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
}

.products .products__box .products__info .products__btn .products__add:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.products .products__box .products__info .products__btn .products__lack span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 3px;
  color: #10653e;
  font-size: 20px;
}

.product .product__slider .flexsliderProduct .slides li img {
  display: none;
}

.product .product__slider .flexsliderProduct .slides li .active {
  display: block;
}

.product .activeSlider {
  display: block !important;
}

.product .activeSlider .flex-control-thumbs {
  display: flex;
  justify-content: space-between;
}

.product .activeSlider .flex-control-thumbs li {
  width: 23%;
}

.product .product__head {
  position: relative;
  min-height: 120px;
}

.product .product__head h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  position: absolute;
  width: 70%;
  margin-top: 0px;
}

.product .product__head .product__price {
  text-align: right;
  font-family: "Lato";
  font-style: normal;
  font-size: 36px;
  line-height: 43px;
  font-weight: 300;
}

.product .product__head .product__price span {
  font-weight: 900;
}

.product .product__head .product__delivery {
  position: absolute;
  right: 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 180.5%;
  text-transform: uppercase;
  bottom: 15px;
}

.product .product__head .product__delivery .product__icon {
  width: 50px;
}

.product .product_element {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  padding-top: 20px;
}

.product .product_element span {
  font-weight: 700;
}

.product .product__ages .product__age {
  margin: 10px 0;
}

.product .product__ages .product__age input {
  height: 19px;
  width: 19px;
  position: relative;
  box-shadow: 0;
}

.product .product__ages .product__age input:checked:checked[type="radio"] {
  border: 1px solid black;
  background-image: url(/assets/img/ellipse.png);
  background-color: #fff;
  background-size: 9px;
  box-shadow: 0;
}

.product .product__ages .product__age label {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.product .product__ages .product__age .form-check-input:focus {
  box-shadow: 0 0 0 0;
}

.product .product__pillow .product__colors {
  margin: 25px 0 15px 0;
}

.product .product__pillow .product__colors .product__color button {
  background-color: transparent;
}

.product .product__pillow .product__colors .product__color button img {
  border-radius: 50%;
  padding: 2px;
}

.product .product__pillow .product__colors .product__color button:focus img {
  border: 2px solid #000;
  padding: 0px;
  font-weight: bold;
}

.product .product__pillow .product__colors .product__color button:focus p {
  font-weight: bold;
  transition: 0.7s;
}

.product .product__pillow .product__colors .product__color:hover p {
  font-weight: bold;
  transition: 0.7s;
}

.product .product__text .product__desc {
  max-width: 80%;
}

.product .product__text .product__btn--show {
  color: #000;
  text-decoration: none;
  font-weight: 700;
  cursor: pointer;
}

.product .product__text .product__btn--show:hover {
  text-decoration: underline;
}

.product .product__text .product__btn-more {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: #012E1A;
  display: inline-block;
  margin-top: 20px;
  width: 190px;
  text-align: center;
}

.product .product__text .modal-content .modal-header .modal-title {
  text-transform: uppercase;
}

.product .product__text .modal-content .modal-body {
  padding-bottom: 35px;
}

.product .product__text img {
  margin-left: 1%;
  transform: scale(0.8);
}

.product .product__text ::after {
  display: none;
}

.product .product__buy {
  margin-top: 45px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.product .product__buy span {
  font-weight: 700;
}

.product .product__buy .product__add {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
}

.product .product__buy .product__add:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.product .btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
  opacity: 1;
}

.cart h1 {
  text-align: center;
  text-transform: uppercase;
}

.cart .noactive {
  display: none !important;
}

.cart .cart__product {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.cart .cart__product .cart__img img {
  max-width: 160px;
  width: 100%;
}

.cart .cart__product .cart__info a {
  color: #000000;
  text-decoration: none;
  transition: 0.7s;
}

.cart .cart__product .cart__info a h3 {
  font-weight: 300;
  font-size: 23px;
  line-height: 29px;
  font-family: "Lato";
  font-style: normal;
}

.cart .cart__product .cart__info a:hover {
  text-decoration: underline;
}

.cart .cart__product .cart__info span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.cart .cart__product .cart__quantity button {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.cart .cart__product .cart__counter {
  display: flex;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  align-items: center;
  color: #012e1a;
}

.cart .cart__product .cart__counter:focus {
  outline: 2px solid #10653e !important;
}

.cart .cart__product .cart__cost span {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
}

.cart .cart__product .cart__x a,
.cart .cart__product .cart__x button {
  width: 50px;
  height: 50px;
  font-family: "Lato", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-weight: 300;
  padding: 1px 10px;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  transition: 0.7s;
}

.cart .cart__product .cart__x a:hover,
.cart .cart__product .cart__x button:hover {
  background-color: #747474;
  color: #fff;
}

.cart .cart__submit {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  min-height: 310px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.cart .cart__submit .cart__all {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.cart .cart__submit .cart__all strong {
  font-size: 24px;
}

.cart .cart__submit .cart__continue {
  font-family: "Lato";
  font-weight: 900;
  font-size: 16px;
  align-items: center;
  text-align: center;
  color: #000000;
  text-decoration: none;
}

.cart .cart__submit .cart__continue:hover {
  font-weight: 700;
}

.cart .cart__submit .cart__pay {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
}

.cart .cart__submit .cart__pay:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.contact h1,
.cash h1,
.return h1 {
  text-align: left;
  text-transform: none;
}

.contact p,
.cash p,
.return p {
  text-align: left;
}

.contact .form-group input,
.contact .form-group textarea,
.cash .form-group input,
.cash .form-group textarea,
.return .form-group input,
.return .form-group textarea {
  border-radius: 0px !important;
}

.contact .form-check-input[type="checkbox"],
.cash .form-check-input[type="checkbox"],
.return .form-check-input[type="checkbox"] {
  border-radius: 0px !important;
  width: 25px;
  height: 25px;
  box-shadow: 0 0 0 0;
}

.contact .form-check-input:focus,
.cash .form-check-input:focus,
.return .form-check-input:focus {
  border-color: #012e1a !important;
}

.contact .form-check-input:checked,
.cash .form-check-input:checked,
.return .form-check-input:checked {
  background-color: #012e1a;
  border-color: #012e1a !important;
}

.contact .form-check-label,
.contact .wpcf7-list-item-label,
.cash .form-check-label,
.cash .wpcf7-list-item-label,
.return .form-check-label,
.return .wpcf7-list-item-label {
  display: block;
  padding: 0 10px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.contact .form-control:focus,
.cash .form-control:focus,
.return .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #012e1a;
  outline: 0;
  box-shadow: 0 0 0 0;
}

.contact .contact__send,
.cash .contact__send,
.return .contact__send {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 40px;
  margin: 20px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

.contact .contact__send:hover,
.cash .contact__send:hover,
.return .contact__send:hover {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  padding: 13px 40px;
  margin: 20px 0;
}

.contact .contact__call,
.cash .contact__call,
.return .contact__call {
  width: 350px;
  border-radius: 15px;
  background-color: #012e1a;
  color: #fff;
}

.contact .contact__call p,
.cash .contact__call p,
.return .contact__call p {
  text-align: center;
}

.contact .contact__call a,
.cash .contact__call a,
.return .contact__call a {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.contact .contact__call a:hover,
.cash .contact__call a:hover,
.return .contact__call a:hover {
  font-size: 38px;
}

.contact .cash__sum h3,
.cash .cash__sum h3,
.return .cash__sum h3 {
  padding-left: 0 !important;
}

.contact .cash__sum .cash__submit,
.cash .cash__sum .cash__submit,
.return .cash__sum .cash__submit {
  background-color: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.contact .cash__sum .cash__submit .cash__item,
.cash .cash__sum .cash__submit .cash__item,
.return .cash__sum .cash__submit .cash__item {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 29px;
}

.contact .cash__sum .cash__submit .cash__item .cash__py,
.cash .cash__sum .cash__submit .cash__item .cash__py,
.return .cash__sum .cash__submit .cash__item .cash__py {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 100px;
  height: 100px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  padding-top: 5px;
  text-align: center;
  color: #000000;
  text-decoration: none !important;
}

.contact .cash__sum .cash__submit .cash__item .cash__py img,
.cash .cash__sum .cash__submit .cash__item .cash__py img,
.return .cash__sum .cash__submit .cash__item .cash__py img {
  width: 100%;
}

.contact .cash__sum .cash__submit .cash__item .cash__py p,
.cash .cash__sum .cash__submit .cash__item .cash__py p,
.return .cash__sum .cash__submit .cash__item .cash__py p {
  font-size: 9px;
  line-height: 10px;
  text-align: center;
  margin-bottom: 0px;
  padding-top: 5px;
}

.contact .cash__sum .cash__submit .cash__item .cash__py span,
.cash .cash__sum .cash__submit .cash__item .cash__py span,
.return .cash__sum .cash__submit .cash__item .cash__py span {
  font-size: 18px;
}

.contact .cash__sum .cash__submit .cash__item strong,
.cash .cash__sum .cash__submit .cash__item strong,
.return .cash__sum .cash__submit .cash__item strong {
  font-weight: 700 !important;
  font-family: "Lato";
  font-size: 24px;
}

.contact .cash__sum .cash__submit .cash__item .cash__cena,
.cash .cash__sum .cash__submit .cash__item .cash__cena,
.return .cash__sum .cash__submit .cash__item .cash__cena {
  font-weight: 900 !important;
  font-family: "Lato";
}

.contact .cash__sum .cash__submit .cash__border,
.cash .cash__sum .cash__submit .cash__border,
.return .cash__sum .cash__submit .cash__border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.contact .cash__pay,
.cash .cash__pay,
.return .cash__pay {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
}

.contact .cash__pay:hover,
.cash .cash__pay:hover,
.return .cash__pay:hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.my__item img {
  width: 100%;
}

.my__baner2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.my__baner2 h2 {
  position: absolute;
  color: #fff;
  max-width: 550px;
}

.flexslider {
  margin: 0 !important;
  border: 0 !important;
}

.flex-control-nav {
  bottom: 25px !important;
  margin-top: 15px !important;
}

.flex-direction-nav {
  display: none !important;
}

.flex-control-paging {
  z-index: 999;
}

.flex-control-paging li a {
  width: 19px !important;
  height: 19px !important;
  background: #fff !important;
}

.flex-control-paging li .flex-active {
  background: #10653e !important;
}

#sliderHome .flex-control-paging {
  display: none;
}

.checkbox-contact {
  float: left;
  margin-left: -1em;
}

.wpcf7-list-item-label {
  padding-left: 1.4em;
  line-height: 18px;
}

.content a {
  color: black;
}

.menu .menu__left {
  padding-right: 70px;
}

.menu .menu__right {
  padding-left: 70px;
}

.wpcf7-not-valid-tip {
  margin-bottom: 7px;
  color: green;
}

.wpcf7-form-control.wpcf7-text.form-control,
.wpcf7-form-control.wpcf7-textarea {
  margin-bottom: 0px !important;
}

.wpcf7-list-item {
  margin-top: .5rem;
}

body {
  margin-top: 168px;
}

.return h1, .return h2, .return strong {
  font-weight: 700;
}

.return p, .return ul {
  text-align: left;
  padding-bottom: 10px;
}

.return p a, .return ul a {
  color: #000;
}

.return p :hover, .return ul :hover {
  color: #10653e;
}

.blog {
  font-family: Lato,sans-serif;
}

.blog h5 b {
  font-weight: 700;
}

.blog .h1 {
  font-weight: 700;
}

.blog h4 {
  font-weight: 400;
}

.blog h4 b {
  font-weight: 700;
}

.blog p {
  color: #012E1A;
  text-align: left;
  margin-bottom: 30px;
}

.blog h2 {
  font-size: 32px;
  margin-bottom: 30px;
}

.blog .blog__btn-next {
  color: #012e1a;
  text-decoration: none;
  width: fit-content;
  padding: 13px 10px;
}

.blog .blog__btn-next:hover {
  color: #fff;
  background: #012e1a;
}

.blog .blog__btn a {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  background-color: #10653e;
}

.blog .blog__btn :hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.blog .article .article__img {
  display: block;
  overflow: hidden;
  position: relative;
}

.blog .article .article__img img {
  width: 100%;
  object-fit: cover;
  transition: .7s;
}

.blog .article .article__img :hover {
  transform: scale(1.1);
}

.blog .article__p {
  padding-bottom: 15px;
}

.blog .article__title {
  font-weight: 400;
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
  min-height: 70px;
}

.blog .article__btn a {
  color: #fff;
  font-family: "Lato", sans-serif;
  background: #012E1A;
  border-radius: 7px;
  padding: 13px 25px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  background-color: #10653e;
}

.blog .article__btn :hover {
  background: #000;
  color: #fff;
  transition: 0.3s;
}

.blog .blog__pagination .pager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.blog .blog__pagination .pager a {
  color: #000;
  text-decoration: none;
}

.blog .blog__pagination .pager :hover {
  color: #10653e;
}

.blog .blog__pagination :last-child {
  border-width: 1px 1px 1px 1px;
}

.blog .blog__pagination :nth-child(2) {
  background: rgba(1, 46, 26, 0.5);
}

footer {
  font-size: 16px;
  line-height: 28.88px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
}

footer ul {
  list-style: none;
  padding-left: 0 !important;
}

footer ul a {
  color: #000000;
  text-decoration: none;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  transition: 0.3s;
}

footer ul a:hover {
  color: #10653E;
}

footer img {
  padding: 5px;
}

footer .border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
}

footer .border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
}

footer .border-end {
  border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
}

footer .footer__social img {
  width: 50px;
}

@media screen and (max-width: 992px) {
  .menu {
    display: none !important;
  }
  .menu__mobile .menu__logo-mobile img {
    width: 65px;
  }
  .menu__mobile .menu__cart {
    position: relative;
    margin-right: 25px;
  }
  .menu__mobile .menu__cart .cart-counter {
    background-color: #fff;
    position: absolute;
    color: #012e1a;
    border-radius: 50%;
    width: 20px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #012e1a;
    left: 27px;
    top: -5px;
  }
  .menu__mobile button {
    border: 0;
  }
}

@media screen and (min-width: 993px) {
  .menu__mobile {
    display: none;
  }
  .main-header {
    transition: 1s;
  }
  .menu__logo img {
    height: 120px;
    transition: 1s ease-in;
  }
  .small {
    top: calc(-168px + 119px);
    transition: 1s;
  }
  .small .menu__logo img {
    height: 69px;
    transition: 1s;
  }
  .small .menu__no a {
    color: transparent !important;
  }
  .hide {
    top: -168px;
  }
  .hide img {
    height: 69px;
    transition: 1s;
  }
  .hide .menu__no a {
    color: transparent !important;
    transition-delay: .5s !important;
  }
  .menu .menu__no ul li a {
    transition-delay: 1s;
  }
}

@media (min-width: 1200px) {
  .product__heading {
    margin-left: 50%;
  }
  .product__slider {
    margin-top: -120px !important;
  }
}

@media (max-width: 1399px) {
  .product_element {
    padding-top: 5px !important;
  }
  .product_element .product__age {
    margin: 0 !important;
  }
  .product__colors {
    margin: 17px 0 0px 0 !important;
  }
  .product__buy {
    margin-top: 35px !important;
    padding-top: 0px !important;
  }
}

@media (max-width: 1199px) {
  .product .product_element {
    padding-top: 5px !important;
  }
  .product .product__ages {
    margin-top: 0px !important;
  }
  .product .product__buy {
    margin-top: 33px !important;
    padding-bottom: 7px !important;
  }
  .product .product__desc {
    max-width: 100% !important;
  }
  .hero__baner .hero__text {
    transform: translate(95%, 5%);
  }
  .hero__baner .hero__text h1 {
    font-size: 38px;
  }
  .hero__baner .hero__text .hero__recomend {
    width: 70%;
    margin: auto;
  }
  .menu__left {
    padding-right: 25px !important;
  }
  .menu__right {
    padding-left: 25px !important;
  }
  .opinions .opinions__box {
    max-width: 355px !important;
  }
  .opinions .opinions__box .opinions__text {
    padding: 55px 40px !important;
    min-height: 240px !important;
  }
  .opinions .opinions__box .opinions__text .opinions__mum {
    bottom: 50px !important;
  }
}

@media (max-width: 991px) {
  .content__gift-img img {
    top: -36% !important;
    left: 4% !important;
  }
  .category__column2 {
    flex-direction: column-reverse;
  }
  .cart__product {
    position: relative;
  }
  .cart__product .cart__x {
    display: block;
    position: absolute;
    right: 40px;
    top: 20px;
  }
  .cart__product .cart__quantity,
  .cart__product .cart__cost {
    margin-top: 70px;
  }
  .cart__product .cart__info a h3 {
    font-size: 22px !important;
  }
  .hero__baner .hero__text {
    transform: translate(95%, 10%);
  }
  .hero__baner .hero__text .hero__recomend {
    width: 100%;
    margin: auto;
  }
  .hero__baner .hero__text .hero__recomend p {
    font-size: 22px;
    max-width: 290px;
    margin-top: 10px;
  }
  .hero__baner .hero__text h1 {
    font-size: 28px;
    max-width: 290px;
  }
  .hero__baner .hero__text p {
    font-size: 14px;
  }
  .hero__baner .hero__text img {
    width: 150px;
  }
  .product .product__buy {
    position: relative !important;
    margin-top: 0px;
  }
  .product .product__ages {
    margin-top: 20px !important;
  }
  .content__choice a .content__name {
    height: 90px !important;
  }
  .content__choice a .content__name h3 {
    font-size: 28px !important;
  }
  .content__choice a .content__name p {
    font-size: 16px !important;
  }
  body {
    margin-top: 82px;
  }
  .hide {
    top: -82px;
  }
  .opinions .opinions__box {
    max-width: 320px !important;
  }
  .opinions .opinions__box .opinions__text {
    padding: 55px 30px !important;
    min-height: 240px !important;
  }
  .opinions .opinions__box .opinions__text .opinions__mum {
    bottom: 50px !important;
  }
  .blog-head {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  .content__gift-img {
    display: flex;
    justify-content: center;
  }
  .content__gift-img img {
    position: relative !important;
    left: 0 !important;
    transform: scale(1.3);
    top: -20% !important;
  }
  .content__gift-text h2,
  .content__gift-text p {
    padding-left: 0px !important;
    text-align: center !important;
  }
  .hero__baner .hero__h1-withe {
    position: relative;
    color: #000;
    top: 20px;
    font-size: 38px;
  }
  .hero__baner .hero__text {
    transform: translate(0%, 0%);
    position: relative !important;
    margin-top: 30px;
  }
  .hero__baner .hero__text h1 {
    font-size: 38px !important;
    max-width: 80%;
  }
  .hero__baner .hero__text .hero__recomend {
    margin-top: 30px;
    width: 100%;
  }
  .hero__baner .hero__text .hero__recomend p {
    font-size: 24px;
  }
  .hero__baner .hero__text img {
    width: 150px;
  }
  .hero__baner .hero__baner-nature {
    display: none;
  }
  .hero__baner .sliderNature {
    display: block;
  }
  .hero__baner .sliderNature .flex-control-paging {
    display: none;
  }
  .cart__quantity,
  .cart__cost {
    margin-top: 20px !important;
  }
  .cart__info {
    position: relative;
  }
  .cart__x {
    position: relative !important;
    top: 10px !important;
    left: 20px !important;
  }
  .products__more,
  .products__add {
    font-size: 13px !important;
  }
  .products__lack span {
    font-size: 16px !important;
  }
  .my__baner2 h2 {
    font-size: 30px !important;
  }
  .product__head h1 {
    font-size: 34px !important;
  }
  .content__choice a .content__name {
    opacity: 1 !important;
    height: 90px !important;
  }
  .content__choice a .content__name h3 {
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .content__choice a .content__name p {
    font-size: 16px !important;
    line-height: 18px !important;
  }
  .opinions .opinions__box {
    max-width: 100% !important;
    margin: 20px 0px;
  }
  .opinions .opinions__box .opinions__text {
    min-height: 200px !important;
  }
  .opinions .opinions__box .opinions__text .opinions__mum {
    display: inline-block !important;
    position: relative !important;
    bottom: auto !important;
    margin-top: 10px !important;
  }
  .opinions .slick-arrow {
    display: none !important;
  }
}

@media (max-width: 575px) {
  .category__info div div {
    padding: 0 30px;
  }
  .category__boxes .category__item {
    padding: 0px !important;
  }
  .category__boxes .category__item .category__text {
    padding: 0 30px;
  }
  .product .product__head h1 {
    font-size: 32px !important;
    width: 100% !important;
    position: relative !important;
    text-align: left;
  }
  .product .product__head .product__price {
    position: relative !important;
    text-align: left !important;
  }
  .product .product__head .product__delivery {
    padding-top: 30px;
    position: relative !important;
    text-align: left !important;
  }
  .product .product__head .product__delivery div {
    justify-content: start !important;
    flex-direction: row-reverse !important;
  }
  .product .product__age {
    margin: 15px 0;
  }
  .footer .border-end {
    border: 0 !important;
  }
  .footer .footer__right {
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    justify-content: left;
    margin-top: 30px;
    padding-top: 50px;
  }
  .footer .footer__right ul {
    width: 100%;
  }
  .hero__baner .hero__text {
    transform: translate(0%, 0%);
    position: relative !important;
    margin-top: 20px;
  }
  .hero__baner .hero__text h1 {
    font-size: 30px !important;
    max-width: 80%;
  }
  .hero__baner .hero__text .hero__recomend p {
    margin-top: 20px;
    width: 100%;
    font-size: 20px;
  }
  .hero__baner .hero__text img {
    width: 120px;
  }
  .cart__product .cart__info a h3 {
    font-size: 16px !important;
  }
  .cart__product .cart__img img {
    padding-left: 15px;
  }
  .cart__product .cart__quantity {
    padding-left: 15px !important;
  }
  .cart__product .cart__x {
    left: 0px !important;
  }
  .cart__product .cart__submit {
    margin-left: 0px !important;
  }
  .content__choice a .content__name {
    opacity: 1 !important;
    height: 80px !important;
  }
  .content__choice a .content__name h3 {
    font-size: 24px !important;
  }
  .content__choice a .content__name p {
    font-size: 14px !important;
  }
  .flex-control-nav {
    margin-top: 10px !important;
  }
  .products .products__box .products__info h5 a {
    font-size: 18px !important;
  }
  .products .products__box .products__info .products__btn .products__add {
    padding: 13px 20px;
  }
}

@media (max-width: 375px) {
  .contact__call a {
    font-size: 26px !important;
  }
  .contact__call a:hover {
    font-size: 28px !important;
  }
  .cart__product .cart__info a h3 {
    font-size: 14px !important;
  }
  .cart__product .cart__info span {
    font-size: 12px !important;
    line-height: 0px !important;
  }
}
