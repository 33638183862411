.hero {
    &__baner {
        position: relative;
        margin: 0 auto;
        .hero__h1-withe {
            @extend %h1;
            position: absolute;
            color: #fff;
            top: 30px;
        }
        .hero__text {
            position: absolute;
            transform: translate(95%, 15%);
            align-items: center;
            h1 {
                @extend %h1;
                max-width: 455px;
                margin: auto;
            }
            .hero__recomend {
                width: 50%;
                text-align: center;
                margin: 0 auto;
                p {
                    @extend %p;
                    margin: auto;
                    margin-top: 20px;
                }
            }
            p {
                @extend %text;
            }
        }

        .sliderNature {
            display: none;
        }
    }
}

.content {
    h1 {
        @extend %h1;
    }
    h2 {
        @extend %h1;
    }
    p {
        @extend %text;
    }
    .content__gift2 {
        background-color: $color-secondary;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
        border-radius: 8px;
        width: 70%;
        position: relative;
        height: 100px;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        transition: 0.7s;
        h2 {
            @extend %h2-bold;
            color: #fff;
            width: 70%;
            transition: 0.7s;
            text-align: left;
            margin: 0;
        }
        p {
            color: #fff;
            width: 70%;
            font-size: 15px;
            text-align: left;
            margin: 0;
        }
        img {
            position: absolute;
            height: 200px;
            top: -50px;
            left: 40px;
            transition: 0.7s;
            transform: scale(0.9);
        }
    }
    .gift{
        background-color: #012E1A;
        max-width: 745px;
        color: #fff;
        margin: 0 auto;
        
        h2{
            @extend %h2;
            color: #fff;
            font-family: 'Lato', sans-serif;
        }
        h4{
            @extend %text-blog;
            font-weight: 400;
            text-align: center;
        }
        .gift__img{
            overflow: hidden;
            img{
                transition: .7s;
                object-fit: cover;
                //height: 100%;
              
            }

        }
        .gift__img:hover{

            img{
                transform: scale(1.1);
            }
            
        }

    }
    .content__gift {
        background-color: #012E1A;
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.35);
        border-radius: 8px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: center;
        transition: 0.7s;
	    .content__gift-text{
            max-width: 450px;
		}
        h2 {
            @extend %h2-bold;
            color: #fff;
            transition: 0.7s;
            text-align: left;
            margin: 0;
						font-size: 20px;
            line-height: 24px;
            padding-left: 35px;
        }
        p {
            color: #fff;
            font-size: 15px;
            text-align: left;
            margin: 0;
						font-family: 'Lato';
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            padding-left: 35px;
        }
        img {
            position: absolute;
            top: -30%;
            left: 5%;
            transition: 0.7s;
        }
    }
    .content__gift:hover {
        transform: scale(1.03);
        img {
            transform: scale(1.1);
            transform: rotate(25deg);
        }
    }
    .content__set {
        h2 {
            color: $color-secondary;
            @extend %p;
            text-align: center;
            text-transform: uppercase;
            
        }
    }

    .content__choice {
        position: relative;

        .content__active,
        .content__nature {
            a {
                position: relative;
                width: 100%;
                img {
                    width: 100%;
                    z-index: -1;
                    object-fit: cover;
                }

                .content__name {
                    opacity: 0;
                    background-color: rgba(1, 46, 26, 0.9);
                    transition: 0.7s;
                    cursor: pointer;
                    position: absolute;
                    bottom: 0;
                    height: 120px;
                    width: 90%;
                    margin-left: 5%;
                    bottom: 5%;
                    h3 {
                        @extend %h2;
                        text-align: center;
                        color: #fff;
                        margin: 0;
              
                    }
                    p {
                        @extend %p;
                        text-align: center;
                        color: #fff;
                        margin: 0;
                    }
                }
            }

            :hover .content__name {
                opacity: 1;
            }
        }
    }
    .content__btn {
        @include btn;
    }
    .content__btn:hover {
        @include btnHover;
    }
    .content__green-text {
        p {
            font-family: "Lato";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 29px;
            text-align: center;
            color: #012e1a;
        }
    }
    .film{
        padding: 10px;
        max-width: 745px;
        margin: 40px auto;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 10px;
        .film__link{
            padding:10px;
            text-decoration: none;
        p{
            @extend %text-blog;
            font-weight: 300;
        }
        .film__yt{
            margin-top:20px;
        }
    }
        iframe{
            border-radius: 12px;
        }
    }
    .opinions{
        padding: 10px;
        max-width: 800px;
        margin: 40px auto;
        .opinions__box{
            max-width: 400px;
            position: relative;
            margin: 0 auto;
            padding: 10px auto;
            margin-top: 20px;
            .quote{
                position: absolute;
                top: -15px;
                left: 30px;
                z-index: 10;
            }
            
           
            .opinions__text{
                background-color: #fff;
                border-radius: 12px;
                padding: 55px 35px;
                min-height: 240px;
                position: relative;
            p{
                text-align: left;
                @extend %text;
                font-style: italic;
                line-height: 19px;
            }
            .opinions__mum{
                position: absolute;
                bottom: 40px;
            }
        }

        }
     

    }



}

.category {
    div {
        padding: 0;
    }
    .category__head {
        h2 {
            text-align: center;
            color: #012e1a;
            font-size: 48px;
            font-family: Oranienbaum, sans-serif;
            font-weight: 400;
        }
    }
    .category__info {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            @extend %h2;
            text-align: left;
            width: 100%;
            color: #012e1a;
        }
        p {
            @extend %text;
            text-align: left;
            color: #012e1a;
        }
        a {
            text-align: left;
            color: #000;
            p {
                @extend %text;
            }
            img {
                transition: 0.7s;
            }
        }
        a:hover {
            img {
                transform: scale(1.1);
            }
        }
        .content__more {
            @include btn;
            background-color: #10653e;
            margin-top: 30px;
            transition: 0.7s;
        }
        .content__more:hover {
            @include btnHover;
        }
    }
    .category__img {
        overflow: hidden;

        img {
            width: 100%;
            padding: 0;
            object-fit: cover;
            transition: 0.7s;
        }
        img:hover {
            transform: scale(1.1);
        }
    }

    .category__green {
        background-color: #012e1a;
        h2 {
            @extend %h2;
            color: #fff;
        }
        p {
            font-family: "Lato";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 180.5%;
            color: #fff;
        }
    }

    .category__boxes {
        .category__item {
            position: relative;
            padding: 5px 10px;
            img {
                width: 100%;
            }
            .category__circle {
                top: 10px;
                right: 30px;
                position: absolute;
                width: 106px;
                height: 106px;
                border-radius: 50%;
                background-color: #10653e;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px;
                color: #ffffff;
                font-family: "Lato";
                font-style: normal;
                font-weight: 900;
                font-size: 15px;
                line-height: 18px;
                text-align: center;
            }
            h4 {
                font-family: "Lato";
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
            }
            p {
                font-family: "Lato";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 29px;
            }
        }
    }
}

.products {
    h1 {
        @extend %h1;
        font-size: 40px;
    }
    .products__text {
        p {
            font-family: "Lato";
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 29px;
            text-align: center;
            color: #012e1a;
        }
    }

    .products__box {
        position: relative;
        .products__img {
           
            a {
                display: block;
                overflow: hidden;
                img {
                    object-fit: cover;
                    transition: 0.7s;
                    height: 100%;
                    width: 100%;
                }
                :hover {
                    transform: scale(1.1);
                }
            }
        }

        .products__info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            h5 {
                min-height: 55px;
                a {
                    font-family: $font-family;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    color: #000;
                    text-decoration: none;
                }
            }
            .products__desc {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                min-height: 110px;
                
            }
            .products__price {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
            }
            .products__btn {
              
                .products__more {
                    @include btn;
                    background-color: #10653e;
                    text-align: center;
                }
                .products__more:hover {
                    @include btnHover;
                }
                .products__add {
                    @include btn;
                }
                .products__add:hover {
                    @include btnHover;
                }
                .products__lack {
                    span {
                        font-family: "Lato";
                        font-style: normal;
                        font-weight: 700;
                        text-align: center;
                        text-transform: uppercase;
                        padding: 3px;
                        color: #10653e;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.product {
    .product__slider {
        .flexsliderProduct {
            .slides {
                li {
                    img {
                        display: none;
                    }
                    .active {
                        display: block;
                    }
                }
            }
        }
    }
    .activeSlider {
        display: block !important;
        .flex-control-thumbs {
            display: flex;
            justify-content: space-between;
            li {
                width: 23%;
            }
        }
    }
    .product__head {
        position: relative;
        min-height: 120px;
        h1 {
            font-family: "Lato";
            font-style: normal;
            font-weight: 500;
            font-size: 36px;
            line-height: 43px;
            position: absolute;
            width: 70%;
            margin-top: 0px;
        }
        .product__price {
            text-align: right;
            font-family: "Lato";
            font-style: normal;
            font-size: 36px;
            line-height: 43px;
            font-weight: 300;
            span {
                font-weight: 900;
            }
        }
        .product__delivery {
            position: absolute;
            right: 0;
            font-family: "Lato";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 180.5%;
            text-transform: uppercase;
            bottom: 15px;
            .product__icon {
                width: 50px;
            }
        }
    }
    .product_element {
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        padding-top: 20px;
        @extend %text-product;
        span {
            font-weight: 700;
        }
    }
    .product__ages {
        .product__age {
            margin: 10px 0;
            input {
                height: 19px;
                width: 19px;
                position: relative;
                box-shadow: 0;
            }
            input:checked:checked[type="radio"] {
                border: 1px solid black;
                background-image: url(/assets/img/ellipse.png);
                background-color: #fff;
                background-size: 9px;
                box-shadow: 0;
            }
            label {
                font-family: "Lato";
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 17px;
            }
            .form-check-input:focus {
                box-shadow: 0 0 0 0;
            }
        }
    }
    .product__pillow {
        .product__colors {
            margin: 25px 0 15px 0;
            .product__color {
                button {
                    background-color: transparent;
                    img {
                        border-radius: 50%;
                        padding: 2px;
                    }
                }
                button:focus {
                    img {
                        border: 2px solid #000;
                        padding: 0px;
                        font-weight: bold;
                    }
                    p {
                        font-weight: bold;
                        transition: 0.7s;
                    }
                }
            }
            .product__color:hover {
                p {
                    font-weight: bold;
                    transition: 0.7s;
                }
            }
        }
    }
    .product__text {
        .product__desc {
            max-width: 80%;
          

        }
        .product__btn--show {
            color: #000;
            text-decoration: none;
            font-weight: 700;
            cursor: pointer;
        }
        .product__btn--show:hover {
            text-decoration: underline;
        }
        .product__btn-more{
            @extend %btnContact;
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            text-transform: uppercase;
            text-decoration: none;
            color: #012E1A;
            display: inline-block;
            margin-top:20px;
            width: 190px;
            text-align: center;
        }
        .modal-content {
            .modal-header {
                .modal-title {
                    @extend %text-product;
                    text-transform: uppercase;
                }
            }
            .modal-body {
                padding-bottom: 35px;
            }
        }

        img {
            margin-left: 1%;
            transform: scale(0.8);
        }
        ::after {
            display: none;
        }
    }
    .product__buy {
        @extend %text-product;
        margin-top: 45px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
        span {
            font-weight: 700;
        }
        .product__add {
            @include btn;
           // padding: 15px 25px;
        }
        .product__add:hover {
            @include btnHover;
        }
    }
    .btn-close:focus {
        outline: 0;
        box-shadow: 0 0 0 0;
        opacity: 1;
    }
}
.cart {
    h1 {
        @extend %p;
        text-align: center;
        text-transform: uppercase;
    }
    .noactive {
        display: none !important;
    }
    .cart__product {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        .cart__img {
            img {
                max-width: 160px;
                width: 100%;
            }
        }
        .cart__info {
            a {
                color: #000000;
                text-decoration: none;
                transition: 0.7s;
                h3 {
                    font-weight: 300;
                    font-size: 23px;
                    line-height: 29px;
                    font-family: "Lato";
                    font-style: normal;
                }
            }
            a:hover {
                text-decoration: underline;
            }
            span {
                font-family: "Lato";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
            }
        }
        .cart__quantity {
            button {
                width: 50px;
                height: 50px;
                background-color: transparent;
                border: 1px solid rgba(0, 0, 0, 0.2);
                @extend %p;
            }
        }
        .cart__counter {
            display: flex;
            width: 50px;
            height: 50px;  
            background-color: transparent;
            border-top: 1px solid rgba(0, 0, 0, 0.2);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            align-items: center;
            color: #012e1a;
            @extend %p;
        }
        .cart__counter:focus {
            outline: 2px solid #10653e !important;
        }

        .cart__cost {
            span {
                font-family: $font-family;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                text-align: right;
            }
        }
        .cart__x {
            a,
            button {
                width: 50px;
                height: 50px;
                font-family: $font-family;
                border: 1px solid rgba(0, 0, 0, 0.5);
                font-weight: 300;
                padding: 1px 10px;
                color: rgba(0, 0, 0, 0.5);
                background-color: transparent;
                transition: 0.7s;
            }
            a:hover,
            button:hover {
                background-color: #747474;
                color: #fff;
            }
        }
    }
    .cart__submit {
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.5);
        min-height: 310px;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        .cart__all {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
            strong {
                font-size: 24px;
            }
        }
        .cart__continue {
            font-family: "Lato";
            font-weight: 900;
            font-size: 16px;
            align-items: center;
            text-align: center;
            color: #000000;
            text-decoration: none;
        }
        .cart__continue:hover {
            font-weight: 700;
        }
        .cart__pay {
            @include btn;
        }
        .cart__pay:hover {
            @include btnHover;
        }
    }
}

.contact,
.cash,
.return {
    h1 {
        text-align: left;
        text-transform: none;
        @extend %p;
    }
    p {
        @extend %text;
        text-align: left;
    }
    .form-group {
        input,
        textarea {
            border-radius: 0px !important;
        }
    }
    .form-check-input[type="checkbox"] {
        border-radius: 0px !important;
        width: 25px;
        height: 25px;
        box-shadow: 0 0 0 0;
    }
    .form-check-input:focus {
        border-color: #012e1a !important;
    }
    .form-check-input:checked {
        background-color: #012e1a;
        border-color: #012e1a !important;
    }
    .form-check-label,
		.wpcf7-list-item-label		{
        display: block;
        padding: 0 10px;
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
    }
    .form-control:focus {
        color: #212529;
        background-color: #fff;
        border-color: #012e1a;
        outline: 0;
        box-shadow: 0 0 0 0;
    }
    .contact__send {
        @extend %btnContact;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 40px;
        margin: 20px 0;
        font-family: "Lato";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: uppercase;
    }
    .contact__send:hover {
        @include btn;
        padding: 13px 40px;
        margin: 20px 0;
    }

    .contact__call {
        width: 350px;
        border-radius: 15px;
        background-color: #012e1a;
        color: #fff;
        p {
            
            text-align: center;
            @extend %p;
        }
        a {
            font-family: "Lato";
            font-style: normal;
            font-weight: 400;
            font-size: 36px;
            line-height: 43px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #fff;
            text-decoration: none;
            padding: 10px;
        }
        a:hover {
            font-size: 38px;
        }
    }
    .cash__sum {
        h3 {
            @extend %p;
            padding-left: 0 !important;
        }
        .cash__submit {
            background-color: #fff !important;
            border: 1px solid rgba(0, 0, 0, 0.2);
            .cash__item {
                font-family: "Lato";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 29px;
                .cash__py {
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                    width: 100px;
                    height: 100px;
                    img {
                        width: 100%;
                    }
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 300;
                    padding-top: 5px;
                    text-align: center;
                    color: #000000;
                    text-decoration: none !important;
                    p {
                        font-size: 9px;
                        line-height: 10px;
                        text-align: center;
                        margin-bottom: 0px;
                        padding-top: 5px;
                    }
                    span {
                        font-size: 18px;
                    }
                }
                strong {
                    font-weight: 700 !important;
                    font-family: "Lato";
                    font-size: 24px;
                }
                .cash__cena {
                    font-weight: 900 !important;
                    font-family: "Lato";
                }
            }
            .cash__border {
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            }
        }
    }
    .cash__pay {
        @include btn;
    }
    .cash__pay:hover {
        @include btnHover;
    }
}

.my__item {
    img {
        width: 100%;
    }
}
.my__baner2 {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        position: absolute;
        @extend %h1;
        color: #fff;
        max-width: 550px;
    }
}

.flexslider {
    margin: 0 !important;
    border: 0 !important;
}
.flex-control-nav {
    bottom: 25px !important;
    margin-top: 15px !important;
}
.flex-direction-nav {
    display: none !important;
}

.flex-control-paging {
    z-index: 999;
    li {
        a {
            width: 19px !important;
            height: 19px !important;
            background: #fff !important;
        }
        .flex-active {
            background: #10653e !important;
        }
    }
}

#sliderHome .flex-control-paging {
    display: none;
}
.checkbox-contact{
    float: left;
    margin-left: -1em;
}
.wpcf7-list-item-label{
    padding-left: 1.4em;
    line-height: 18px;
}
.content{
    a{
        color: black;
    }
}
.menu .menu__left {
    padding-right: 70px;
}
.menu .menu__right {
    padding-left: 70px;
}
.wpcf7-not-valid-tip{
    margin-bottom: 7px;
    color: green;
}
.wpcf7-form-control.wpcf7-text.form-control,
.wpcf7-form-control.wpcf7-textarea {
    margin-bottom: 0px !important;
}
.wpcf7-list-item{
    margin-top: .5rem;
}
body{
    margin-top: $main-header-height;
}