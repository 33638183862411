.return{
    h1, h2, strong{
    
       font-weight: 700;
    }
    p, ul{
     
            @extend %text;
            text-align: left;
            padding-bottom: 10px;
            a{
                color: #000;
            }
            :hover{
                color: #10653e;
            }
    }
}