%h1 {
    text-align: center;
    color: $color-secondary;
    font-size: 48px;
    font-family: $font-heading;
    font-weight: 400;
}

%text {
    font-size: 16px;
    line-height: 29px;
    font-family: $font-family;
    font-weight: 300;
    text-align: center;
}
%h2{
    font-family: $font-heading;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    }



%btnContact{
    background: rgba(1, 46, 26, 0.2);
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 13px 10px;
    gap: 10px;
}
%p{
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
}
%h2-bold{
    font-family: $font-family;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 36px;
    color: #fff;
}
%text-product{
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
}
%text-blog{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}