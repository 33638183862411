@mixin btn{
    color: #fff;
    font-family: $font-family;
    background: $color-secondary;
    border-radius: 7px;
    padding: 13px 25px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    transition: 0.3s;
    text-align: center;
}
@mixin btnHover {
    background: #000;
    color: #fff;
    transition: 0.3s;
}
@mixin title {
    text-align: center;
    color: $color-secondary;
    font-size: 48px;
    font-family: Oranienbaum;
    font-weight: 400;
}
