.blog {
    font-family: Lato,sans-serif;
    h5{
     @extend %text-product;
        b{
            font-weight: 700;
        }
    }

    .h1{
        @extend %h2;
        font-weight: 700;
    }
    h4{
        @extend %text-blog;   
        font-weight: 400;
        b{
            font-weight: 700;
        }
    }
   
    p{
        @extend %text;
        color: #012E1A;
        text-align: left;
        margin-bottom: 30px;

    }
    h2{
        font-size: 32px;
        margin-bottom: 30px;

    }
    .blog__btn-next{
      
        @extend %btnContact;
        color: #012e1a;
        text-decoration: none;
        width: fit-content;
        padding: 13px 10px;

        
    }
    .blog__btn-next:hover{
        color: #fff;
    background: #012e1a;
    }
    .blog__btn{
        a {
            @include btn;
            background-color: #10653e;
        }

        :hover {
            @include btnHover;
      
        }
      
    }
    .article {
        .article__img {
            display: block;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                object-fit: cover;
                transition: .7s;
                
            }
            :hover {
                transform: scale(1.1);
            }
        }
        &__p {
            @extend %text-product;
            padding-bottom: 15px;
        }
        &__title {
            @extend %p;
            font-weight: 400;
            display: flex;
            align-items: flex-end;
            padding-bottom: 10px;
            min-height: 70px;
        }
        &__btn {
            a {
                @include btn;
                background-color: #10653e;
            }

            :hover {
                @include btnHover;
          
            }
        }
    }
    .blog__pagination{
        .pager{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            box-sizing: border-box;
            border-width: 1px 0px 1px 1px;
            border-style: solid;
            border-color: rgba(0, 0, 0, 0.2);
            @extend %p;
            cursor: pointer;
            a{
                color: #000;
                text-decoration: none;
            }
            :hover{
                color: #10653e;
            
            }
        }
        :last-child{
            border-width: 1px 1px 1px 1px;
        }
        :nth-child(2){
            
        background: rgba(1, 46, 26, 0.5);
        }
    }
    
}
